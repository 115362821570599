import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/kebersihan'

export default {
  get (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  show (idKebersihan) {
    return httpClient.get(`${endpoint}/${idKebersihan}`)
  },

  create (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  update (params) {
    return httpClient.post(`${endpoint}/update`, params)
  },

  delete (id) {
    return httpClient.delete(`${endpoint}/${id}`)
  }
}
